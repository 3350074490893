import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { Wrapper, Container } from "../styles"
import { Button } from "../components/Button"
import CompareTableSimple from "../components/CompareTableSimple"

const ComparePage = () => (
  <>
    <SEO
      title="Compare Rachio Products to Find Which is Right for You"
      description="Use our product comparison tool to find the right smart watering solution for you. Get leading smart sprinkler control and advanced weather monitoring with Rachio 3 or Generation 2 Smart Sprinkler Controllers, and groundbreaking leak detection with the Rachio Wireless Flow Meter."
      pathname="/compare/"
    />
    <Wrapper width="tight">
      <div css="text-align: center;">
        <h1 css="color: var(--primaryColor)">Rachio Product Comparison</h1>
        <p className="largest">
          Meet the Rachio family of products - each designed to extend your
          homeowners&apos; smart home capabilities to the lawn. Rachio Smart
          Sprinkler Controllers automatically create watering schedules that
          lower water bills and ensure healthy yards, while the mobile app
          allows management of the sprinkler system from anywhere.
        </p>
      </div>
    </Wrapper>
    <Wrapper>
      <hr css="padding-top: var(--basePadding)" />
      <Container css="margin-top: var(--basePadding)">
        <CompareTableSimple />
      </Container>
      <hr />
      <section css="text-align: center; padding-top: 0">
        <h4 css="color: var(--primaryColor)">
          Looking for a place to buy Rachio products?
        </h4>
        <Button as={Link} to="/where-to-buy/">
          Where To Buy
        </Button>
      </section>
    </Wrapper>
  </>
)

export default ComparePage
